.panelBody{
    height: 95vh;
    width: 25vw;

    position: absolute;
    top: 5vh;
    left : 0vw;

    display: flex ;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;

    border-radius: 12px;
    

    background-color: whitesmoke;
    border: 1px solid black;

    
    border-radius: 5px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    
}

.card-container{

    position : relative;
    
    border: 1px grey solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 5px;
    
    display: flex ;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
   
    height: 80%;
    width: 90%;
    padding: 5px;

    /*TODO: Change the style of the scroll bar*/
    overflow-x: hidden;
    overflow-y : scroll;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */

}   

.card-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
