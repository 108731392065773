.SearchBarBody {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 10%;
  width: 90%;
  top: 3vh;
  
  margin-bottom: 70px;
  padding: 5px;
  
  border: solid grey 1px;

  border-radius: 5px;
  
}

.bar {
  position: absolute;

  left :5%;

  height: 5vh;
  width: 40%;

  bottom: 15%;

}
.distance-select-box {
  position: absolute;

  height: 5vh;
  width: 10vw;

  left: 50%;
  bottom: 15%;
}

.icon {
  position: absolute;

  top: 10%;
  left: 45%;

}


