.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5vh;
  background-color: lightblue;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.header-title {
  color: white;
  position: relative;

  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  right : 6.5%;

  height: 5vh;
  width : 20vw;
}

.header-title-icon {
  max-height: 100px;
  max-width: 200px;
  width: 40px;
  height: 4vh;

 
}

.header-title-icon img {
  width: 100%;
  height: 4vh;
}
