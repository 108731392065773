.map-container {
  position: absolute;
  top: 5vh;
  left: 25vw;

  border: 1px solid lightgrey;
  color: white;
  border-radius: 12px;

  width: 75vw;
  height: 69.8vh;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 75vw;
  height: 100vh;


}


.directions-container {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;

  height : 30vh;
  width: 70vw;
  
  top :80%;
  left : 40%;

  overflow: auto;

  
  border : solid 3px black;
}

.lower-window{
  position: absolute;

  height: 24.7vh;
  width: 75vw;

  top : 75%;
  left : 25.1vw;

  flex-basis: 15rem;
  flex-grow: 1;
  padding: 1rem;
  box-sizing: border-box;
  overflow-x: scroll;

  border : solid 1px black;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;


}
